const coins = [
    {
        id: 1,
        name: "KES",
        preffix: "kshs",
        image: "kes.png",
        network: 'kshs',
        min: "10.00",
        active: true
    },
];

export default coins;