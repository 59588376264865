import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Event } from '../../../../Helper'

export default class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
  }

  componentDidMount() {
    Event.on('show_privacy', () => {
      this.setState({ show: true, effect: 'pulse' })
    })
  }

  showPrivacy = (e) => {
    e.preventDefault()
    this.setState({ show: true, effect: 'pulse' })
  }

  closePrivacy = () => {
    this.setState({ show: false, effect: 'zoomOut' })
  }

  render() {
    return (
      <>
        <Link className="nav-link-x" to={'#'} onClick={this.showPrivacy}>
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M168.531 215.469l-29.864 29.864 96 96L448 128l-29.864-29.864-183.469 182.395-66.136-65.062zm236.802 189.864H106.667V106.667H320V64H106.667C83.198 64 64 83.198 64 106.667v298.666C64 428.802 83.198 448 106.667 448h298.666C428.802 448 448 428.802 448 405.333V234.667h-42.667v170.666z"></path>
                     </svg> */}
          <span className={'cpt'}>Privacy Policy</span>
        </Link>
        <Modal
          size="lg"
          centered={true}
          backdrop={'static'}
          show={this.state.show}
          onHide={this.closePrivacy}
          aria-labelledby="general-lg-modal"
          className={'animated ' + this.state.effect}
        >
          <Modal.Header className={'font-light'}>
            Privacy Policy
            <button
              type="button"
              className="close p-2"
              onClick={this.closePrivacy}
            >
              <i className={'mdi mdi-close'} />
            </button>
          </Modal.Header>
          <Modal.Body className={'modal-reader'}>
            <h4 className='text-white'>
              Introduction and general terms
            </h4>
            <p>
              <a href="http://www.tekamia.co.ke/">
                This privacy policy is used to inform website and all variants
                thereof, including our mobile app (our Channels) visitors our
                policies on collection, use, and disclosure of Personal
                Information and data collected from website visitors. At tekamia
                we are committed to protecting and respecting your privacy and
                maintaining the confidence and trust of our customers and
                visitors to our Channels. This Policy explains how and why your
                Personal Information is collected, through the use of our
                Channels why it is collected and how it is kept secure. The
                terms used in this Privacy Policy have the same meanings as in
                our Terms and Conditions, which are accessible at
                www.tekamia.co.ke, unless otherwise defined in this Privacy
                Policy. This policy should be read and applied together with the
                General Terms and Conditions available at www.tekamia.co.ke
              </a>
            </p>
            <h4 className='text-white'>
              Collection of information
            </h4>
            <p className='text-white'>
              We collect your personal information when you interact with us or
              use our services in any of the following ways (this list is not
              exhaustive):
            </p>
          
            <ol>
              <li>
                <p>
                  Visit, access or use any of tekamia services and our Channels.
                </p>
              </li>
              <li>
                <p>
                  <a href="http://www.tekamia.co.ke/">
                    Subscribing or registering to our services and products,
                    including but not limited to; betting and gaming services
                    offered at
                  </a>
                  <span>
                    www.tekamia.co.ke
                  </span>
                  , promotional offers.
                </p>
              </li>
              <li>
                <p>
                  Participating in any of tekamia platforms for example;
                  discussion boards on our site or social media platforms,
                  entering a promotion or survey organized by us, reporting a
                  problem with our mobile app, site, service or product or
                  asking for more information on a service or product.
                </p>
              </li>
              <li>
                <p>
                  We may also collect your information from third party sources,
                  including but not limited to fraud prevention agencies and
                  marketing agencies.
                </p>
              </li>
            </ol>
          
            <h4 className='text-white'>
              Information we collect
            </h4>
          
            <p>
              The information we collect and store about you when you interact
              with us, as described, above may include, but is not limited to,
              the following:
            </p>
          
            <ol>
              <li>
                <p>
                  Personal Information
                  <span>
                    - we may collect personal information when you register for
                    or use our services, this information may include; name, ID
                    number, age, mobile number, address, date of birth, email
                    address, gender, username, password, social media profiles,
                    personal preferences collected in surveys and recordings.
                  </span>
                </p>
              </li>
              <li >
                <p>
                  Technical information
                  <span>
                    ; including the type of mobile device you use, unique device
                    identifiers such as internet protocol (IP) address of your
                    computer or smart phone, or your device&#39;s IMEI or serial
                    number or SIM card number of your non-smart phone or feature
                    phone, your login information, browser type and version,
                    time zone setting, browser plug-in types and versions,
                    operating system and platform, information about the SIM
                    card used by your device, mobile network information;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  Financial information
                  <span>
                    ; including your transaction information when you withdraw
                    from or deposit into your tekamia account, information we
                    obtain from fraud prevention agencies, Anti-Money Laundering
                    agencies and any government regulator;
                  </span>
                </p>
              </li>
              <li>
                <p >
                  Content information
                  <span>
                    ; including information stored on your device, including
                    contact lists, call logs, SMS logs, social media friends,
                    followers and contacts, contact lists from other social
                    media accounts, photos, videos or other digital content;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  Log information
                  <span>
                    including details of your use of any of our Channels;
                    including, but not limited to, traffic data, location data,
                    weblogs and other communication data;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  Information about your visit, including the full Uniform
                  Resource Locators (URL) clickstream to, through and from our
                  App, system and network (including date and time); services or
                  products you viewed or searched for or requested; page
                  response times, download errors, length of visits to certain
                  pages, page interaction information and any phone number used
                  to request a service or call our customer service number;
                </p>
              </li>
              <li>
                <p>
                  Location information
                  <span>
                    using GPS technology or other location finding services to
                    determine your current location.
                  </span>
                </p>
              </li>
            </ol>
          
            
            <h4 className='text-white'>
              use of your information
            </h4>
          
            <p >
              We recognize the trust and confidence our customers and our
              Channels’ visitors place in us as a service provider. In return,
              tekamia is open about why we collect your information. We may use
              your information for the following purposes (this list is not
              exhaustive):
            </p>
          
            <ol>
              <li>
                <p>
                  to carry out our obligations arising from any agreements
                  entered into between you and tekamia and to provide you with
                  the information, products and services that you request or
                  agree to receive from us;
                </p>
              </li>
              <li >
                <p>
                  marketing activities to notify you about changes to our
                  services and products, keeping you informed about new products
                  and services unless you opt out of receiving marketing
                  messages;
                </p>
              </li>
              <li>
                <p>
                  to comply with any legal or regulatory requirement;
                </p>
              </li>
              <li>
                <p>
                  understanding your use of our services to ensure that content
                  from our Channels is presented in the most effective manner
                  for you and for your computer, your mobile phone or other
                  device you use to access our Channels;
                </p>
              </li>
              <li>
                <p>
                  to respond to any of your queries;
                </p>
              </li>
              <li>
                <p>
                  to administer our Channels and for internal operations,
                  including troubleshooting, data analysis, testing, research,
                  statistical and survey purposes;
                </p>
              </li>
              <li>
                <p>
                  &gt;to improve our Channels;
                </p>
              </li>
              <li>
                <p>
                  to allow you to participate in interactive features of our
                  services and products, when you choose to do so;
                </p>
              </li>
              <li>
                <p>
                  in business practices like quality control and training;
                </p>
              </li>
              <li>
                <p>
                  to measure or understand the effectiveness of advertising we
                  serve to you and others, and to deliver (with your consent)
                  relevant advertising to you;
                </p>
              </li>
              <li>
                <p>
                  providing aggregated data (this data is anonymized) to third
                  parties for research and scientific purpose; and
                </p>
              </li>
              <li>
                <p>
                  the information we collect from third parties enables us to
                  offer you the services under any agreement entered into with
                  tekamia.
                </p>
              </li>
            </ol>
          
           
            <h4 className='text-white'>
              lawful basis for processing your information
            </h4>
            <p>
              In compliance with the relevant local and international data
              protection laws, we will only process your information in
              accordance with any of the lawful basis provided for under these
              laws which include:
            </p>
          
            <ol>
              <li>
                <p>
                  The performance of an agreement with you;
                </p>
              </li>
              <li >
                <p>
                  tekamia legitimate business interests;
                </p>
              </li>
              <li>
                <p>
                  Compliance with any mandatory legal obligations;
                </p>
              </li>
              <li>
                <p>
                  Your consent;
                </p>
              </li>
              <li>
                <p>
                  Your vital interest; and
                </p>
              </li>
              <li>
                <p>
                  Public interest.
                </p>
              </li>
              <li>
                <p>
                  retention of information
                </p>
              </li>
              <li>
                <p>
                  We will only retain your personal data for as long as
                  reasonably necessary to fulfil the purposes we collected it
                  for, including for the purposes of satisfying any legal,
                  regulatory, tax, accounting or reporting requirements or any
                  obligations to you under an agreement with tekamia. We may
                  retain your personal data for a longer period in the event of
                  a complaint or if we reasonably believe there is a prospect of
                  litigation in respect to our relationship with you.
                </p>
              </li>
              <li>
                <p>
                  To determine the appropriate retention period for personal
                  data, we consider the amount, nature and sensitivity of the
                  personal data, the potential risk of harm from unauthorized
                  use or disclosure of your personal data, the purposes for
                  which we process your personal data and whether we can achieve
                  those purposes through other means, the need to comply with
                  our internal policy and the applicable legal, regulatory, tax,
                  accounting or other requirements.
                </p>
              </li>
              <li>
                <p>
                  Anonymized information that can no longer be associated with
                  you may be held indefinitely.
                </p>
              </li>
              <li>
                <p>
                  Your bet history is available to you on our platform for
                  twelve (12) weeks. The data is thereafter archived, and can be
                  availed upon written request by You. You can log into your
                  account at any time and view a statement showing your bet
                  history within the twelve
                </p>
              </li>
            </ol>
            <p>
              (12) weeks. Should you notice any errors and or mistakes, you are
              required to immediately notify the Company.
            </p>
          
             
            <h4 className='text-white'>
              Disclosure of information
            </h4>
          
            <p>
              Any disclosure of your information shall be in accordance with
              applicable law and regulations. We may disclose your information
              to:
            </p>
            <ol>
              <li>
                <p>
                  law-enforcement agencies, regulatory authorities, courts or
                  other statutory authorities in response to a demand issued
                  with the appropriate lawful mandate and where the form and
                  scope of the demand is compliant with the law.
                </p>
              </li>
              <li >
                <p>
                  fraud prevention and Anti money laundering agencies;
                </p>
              </li>
              <li>
                <p>
                  publicly available and/or restricted government databases to
                  verify your identity information in order to comply with
                  regulatory requirements;
                </p>
              </li>
              <li>
                <p>
                  survey agencies that conduct surveys on behalf of tekamia; and
                </p>
              </li>
              <li>
                <p>
                  any other person that we deem legitimately necessary to share
                  the data with.
                </p>
              </li>
            </ol>
            <p>
              We will get your express consent before we share your personal
              data with any third party for direct marketing purposes.
            </p>
          
              
            <h4 className='text-white'>
              Direct marketing
            </h4>
          
            <ol>
              <li>
                <p>
                  You may be required to opt in or give any other form of
                  explicit consent before receiving marketing messages from us.
                </p>
              </li>
              <li >
                <p>
                  You can ask us to stop sending you marketing messages at any
                  time by writing to us or checking or unchecking relevant boxes
                  to adjust your marketing preferences or by following the
                  optout links on any marketing message sent to you or by
                  contacting us through the provided contacts.
                </p>
              </li>
              <li>
                <p>
                  Where you opt out of receiving these marketing messages, this
                  will not apply to personal data provided to us as a result of
                  a product, service already taken up, product or service
                  experience or other transactions.
                </p>
              </li>
            </ol>
          
             
            <h4 className='text-white'>
              Cookies
            </h4>
          
            <p >
              Cookies are files with small amount of data that are commonly used
              an anonymous unique identifier. These are sent to your browser
              from our Channels that you visit and are stored on your
              phone/computer. We use cookies for the following purposes:
            </p>
          
            <ol>
              <li>
                <p>
                  to identify the user's preferred language, so it can be
                  automatically selected when the user returns to our Channels;
                </p>
              </li>
              <li >
                <p>
                  to ensure that bets placed by the customer are associated with
                  the customer&#39;s bet slip and account;
                </p>
              </li>
              <li>
                <p>
                  to ensure that the customer receives any bonuses for which
                  they are eligible, and
                </p>
              </li>
              <li>
                <p>
                  for analysis of our Channels' traffic, so as to allow us to
                  make suitable improvements.
                </p>
              </li>
            </ol>
          
            <p >
              You have the option to either accept or refuse these cookies, and
              know when a cookie is being sent to your phone/computer. If you
              choose to refuse our cookies, you may not be able to use some
              features of our Channels.
            </p>
          
            
            <h4 className='text-white'>
              safeguarding and protection of information
            </h4>
          
            <p >
              tekamia has put in place technical and operational measures to
              protect your information from unauthorized access, accidental loss
              or destruction.
            </p>
          
             
            <h4 className='text-white'>
              your rights
            </h4>
          
            <p >
              Subject to legal and contractual exceptions, you have rights under
              data protection laws in relation to your personal data. These are
              listed below:
            </p>
          
            <ol>
              <li>
                <p>
                  Right to be informed that we are collecting personal data
                  about you;
                </p>
              </li>
              <li >
                <p>
                  Right to access personal data that we hold about you and
                  request for information about how we process it;
                </p>
              </li>
              <li>
                <p >
                  Right to request that we correct your personal data where it
                  is inaccurate or incomplete;
                </p>
              </li>
              <li>
                <p>
                  Right to request that we erase your personal data noting that
                  we may continue to retain your information if obligated by the
                  law or entitled to do so;
                </p>
              </li>
              <li>
                <p>
                  Right to object and withdraw your consent to processing of
                  your personal data. We may continue to process if we have a
                  legitimate or legal reason to do so;
                </p>
              </li>
              <li>
                <p>
                  Right to request restricted processing of your personal data
                  noting that we may be entitled or legally obligated to
                  continue processing your data and refuse your request; and
                </p>
              </li>
              <li>
                <p>
                  Right to request transfer of your personal data in an
                  electronic format.
                </p>
              </li>
            </ol>
          
            <p >
              <a href="mailto:support@tekamia.co.ke">
                If you wish to exercise any of the rights set out above, please
                contact us on
              </a>
              <a href="mailto:support@tekamia.co.ke">
                support@tekamia.co.ke
              </a>
            </p>
          
            <p>
              We may need to request specific information from you to help us
              confirm your identity and ensure your right to access your
              personal data (or to exercise any of your other rights). This is a
              security measure to ensure that personal data is not disclosed to
              any person who has no right to receive it. We may also contact you
              to ask you for further information in relation to your request to
              speed up our response.
            </p>
          
            <p >
              We try to respond to all legitimate requests within reasonable
              time. Occasionally it could take us longer if your request is
              particularly complex or you have made a number of requests. In
              this case, we will notify you and keep you updated.
            </p>
          
            
            <h4 className='text-white'>
              right to lodge complaint
            </h4>
          
            <p >
              You have the right to lodge a complaint with the relevant
              supervisory authority that is tasked with personal data protection
              within the Republic of Kenya.
            </p>
          
              
            <h4 className='text-white'>
              Non-compliance with this statement
            </h4>
          
            <p >
              tekamia shall have the right to terminate any agreement with you
              for failure to comply with the provisions of this Privacy Policy
              and its Terms and Conditions and reject any application for
              information contrary to this Privacy Policy.
            </p>
          
             
            <h4 className='text-white'>
              Amendments to this statement
            </h4>
          
            <p >
              You should check our Channels frequently to see recent changes.
              Unless stated otherwise, our current Privacy Policy applies to all
              information that we have about you and your account. We stand
              behind the promises we make, however, and will never materially
              change our policies and practices to make them less protective of
              user information collected in the past without the consent of
              affected customers.
            </p>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}
