import React from 'react';  
// import './Static/css/bootstrap.min.css'; 
import Carousel from 'react-bootstrap/Carousel';  
    
export default function App() {  
  return (  
    <div style={{ display: 'block' }}>
      <Carousel controls={false} indicators={false}>  
        {/* <Carousel.Item interval={4000}>  
          <img className="d-block w-100" src="/assets/images/KAPAMIA_NEW.png" alt="Image One"  /> 
        </Carousel.Item>  

        <Carousel.Item interval={5000}>  
          <img className="d-block w-100" src="/assets/images/KAPAMIA-BONUS.gif" alt="Image One"  /> 
        </Carousel.Item>  */}

        {/* <Carousel.Item interval={1500}>  
          <img  
            className="d-block w-100"  
              src="https://play.pakakumi.com/slide-invite-friends.jpg"  
            alt="Image Two"  
          />
        </Carousel.Item>   */}
      </Carousel>  
    </div>  
  );  
}  